.wrapperDocuments{
    justify-content: center;
    .documentList{
        list-style: none;
        background: #9DE1E8;
        width: 337px;
        height: 65px;
        padding: 10px;
        // box-shadow: 4px 4px 4px #09679B;
        font-size: 20px;
        font-weight: 500;
    }
    ul{
        font-size: 22px;
        font-weight: 600;
    }
    .downArrow{
        margin: 11px;
        color: white;
        background-color: black;
        padding: 3px;
        width: 24px;
        height: 25px;
        border: 1px solid black;
        border-radius: 50%;
    }
    li:hover{
        background-color: #065ec9;
        cursor: pointer;
      }
    .document{
        background: #9DE1E8;
        padding: 10px;
        width: 337px;
        font-size: 18px;
        border-bottom: 1px solid black;
    }
    .docDopdown{
        margin-top: 10%;
     .document-list{
        list-style: none; 
        padding-left: 0px;   
     }
    }
    .register{
        margin-top: 5%;
        width: 337px;
        height: 60px;
        background: #065ec9;
        color: #ffffff;
    }
    .modalFade{
        display: block;
        top: 20%;
    }
    .modalDoc{
        position: fixed; 
        z-index: 1; 
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%;
        overflow: auto; 
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4); 
    }
    .channel-name{
        color: red;
    }
    .doc-list{
        width: 100%;
    }
    .modalMain{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        padding: 30px;
        .modalMainSub{
            border-radius: 10px;
            background: #2ea3f2;
            overflow: auto;
            display: flex;
            align-items: center;
            width: 70%;
            justify-content: space-evenly;
            padding-top: 10px;
            padding-bottom: 10px;
            color: white;
            .modalLeft{
                height: 95%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                .modalLeftHeading{
                    padding: 40px 0px;
                    display: flex;
                }
            }
            .modalRight{
                height: 90%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                img{
                    width: 350px;
                }
            }
        }
    }
}