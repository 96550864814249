@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Sans:wght@400;600;800&family=Lobster&family=Poppins:wght@400;600;800&display=swap');
*,*::after, *::before{
    /* overflow-x: hidden; These 2 lines are used to hide the */
    /* overflow-y: auto;   x-axis scroll just incase they appear */
    box-sizing: border-box;
    margin: 0;
    text-align: center;
    font-family: 'Raleway', sans-serif;
}


.App {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
}
.App .navbar {
  height: 10vh;
  justify-content: space-between;
}
.App header{
  height: 100%;
}
.App header .content{
  height: 100%;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
  background: transparent;
  margin: 0;
  float: left;
  height: 4rem;
  margin: .5rem ;
  cursor: pointer;
}


.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-note {
  color: #61dafb;
  background-color: blanchedalmond;
}

.pop-upbox {
  /* background-color: #095cc4; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-footer{
    justify-content: center;
}

.Scanner-box {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #3795bd;
}

h4 {
  color: #fff;
  text-align: center;
  margin: 0px auto 16px;
}

.nav-link{
    color:whitesmoke !important;
    margin-right: 1rem;
}

.nav-link:hover{
    background-color: rgba(255, 173, 0, 1);
    color: rgba(5, 22, 77,1) !important;
    border-radius: 4px;
}

.nav-buttons{
    text-transform: uppercase;
    display: inline-block;
    padding: 10px ;
    /* background-color: rgba(0, 136, 169, 1); */
    border: none;
    border-radius: .6rem;
    cursor: pointer;
    transition: all .3s ease 0s;
    border: 2px solid rgba(255, 173, 0, 1);
}
.nav-buttons:hover{
    color: rgb(5, 22, 77);
    background-color: rgba(255, 173, 0, 1);
}

.none {
  display: none
}

svg {
  padding: 21px;
  background: #fff;
}

.data-container {
  margin: 25px 10px 0px 10px;
  padding: 25px;
  border: 1px solid white;
  color: white;
  width: 80%;
}

.detail {
  display: flex;
}

p.info {
  padding-left: 10px;
}


li,a,button{
    letter-spacing: 1px;
    /* font-family: "Montserrat", san-serif; */
    font-weight: 500;
    font-size: 1rem;
    color: whitesmoke;
    text-decoration: none;

}
.close-button{
    width: 100px;
    height: 50px;
    font-weight: bold;
    border: 2px red solid;
    background-color: white;
    color: red !important;
}
.close-button:active{
    border: 2px red solid !important;
}


.logo{
    background: transparent;
    margin: 0;
    float: left;
    height: 4rem;
    margin: .5rem ;
    cursor: pointer;
}

ul{
    display: flex;
    justify-content: flex-start;
    margin-left: -3em;
}
nav{
  background-color: rgba(5, 22, 77,1);
  border-bottom: 2px solid rgba(255, 173, 0, 1);
}

.nav__buttons{
    margin: 0;
}

.nav__links{

    height:max-content;
    margin-right: auto;
    padding: 0 .5rem;


}

.nav__links li{
    display: flex;
    justify-content: flex-start;
    
}

.nav__links li a {
    display: inline-block;
    padding: 10px 10px;
    transition: all .2s ;
}

.nav__links li a:hover {
    text-decoration: none;
    background-color: whitesmoke;
    color:rgba(5, 22, 77,1);; 
    /* background: linear-gradient(91deg,#9a0cec,#48bfe3); */
    border-radius: 5px;
}

.cta-1{
    margin-right: 1.2rem;
    float: right;
}
.cta-2{
  margin-left: 1rem;
}

button{
    text-transform: uppercase;
    display: inline-block;
    padding: 10px ;
    /* background-color: rgba(0, 136, 169, 1); */
    border: none;
    border-radius: .6rem;
    cursor: pointer;
    transition: all .3s ease 0s;
    background:rgba(5, 22, 77,1);;
    border: 2px solid red;

}

button:hover{
    background: white;
    color: rgba(5, 22, 77,1);;
    
}
.hero__image{
    width: 100%;
    margin: 0 auto;

}


/*----Hero-section----*/

.hero-content{
    display: flex;
    justify-content: center;
    height: 90vh;
    background: linear-gradient( rgba(0,0,0,.3), rgba(55, 50, 50, 0.6)), url('./images/images/sydney.jpg');
    background-size: cover;
    background-position: top;
    align-items: right;

}


.header-contents{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    align-self: flex-end;
    margin: 0 auto 3rem;
}
.hero-header {
    margin: 0 auto;
    font-size: 3rem;
    font-weight: 800;
    text-decoration: solid;
    color: whitesmoke;
}
.hero-text{
    margin: 1.2rem auto;
    letter-spacing: 1px;
    text-align: center;
    width: 700px;
    font-size: 1.3rem;
    color: whitesmoke;
    text-transform: capitalize;

}
.form{
    margin: 1rem auto;
    width: 400px;
    height: 200px;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
input{
    font-size: 1.8rem;
}
input[type=text],
input[type=email]{
    width: 100%;
    padding: 10px 20px;
    margin: 1rem 0;
    display: block;
    font-size: 1rem; 
    background-color: whitesmoke;    
}
input[type=submit]{
    width: 10 0%;
    background-color: rgb(5, 22, 77);
    color: white;
    padding: 14px 20px;
    margin:  1rem auto;
    border: none;
    border-radius: 3px;
    cursor: pointer; 
    text-transform: uppercase ;
    font-size: 1.5rem; 
    border-bottom: 2px solid rgba(255, 173, 0, 1);
    border-right: 2px solid rgb(255, 173, 0, 1);
}
input::placeholder{ /*For handling the text visible inside the input field*/
    font-size: 1.7rem;
    color: hsl(0, 1%, 47%);
}
input[type=submit]:hover{
    background-color:rgb(5, 31, 114);
    transition: all .3s ease-in-out .1s;
}


/*===================Features section================*/
.feature-section{
    background: linear-gradient( to top right , rgba(5, 31, 114, 1), rgba(9, 38, 131, 0.8) );
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100vw;
    margin: 0 auto;
    justify-content: center;
    max-height: 100vh;


}

.feature__row{
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 5rem auto;
    display: flex;
    justify-content: space-evenly;
    height: 65vh;
    width: 90vw;

}

.feature__row-2{
    margin-top: -16rem;
}

.feature-box{
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgba(5, 22, 77,1);
    margin: .5rem auto;
    /* padding: 2rem; */
    width: 35vw;
    height: 55%;
    position: relative; 
    box-shadow: rgba(33, 32, 32, 0.8);
  
    

}

.feature-box:hover{
    
    transform: translateY(-1.2rem) translateY(1.1rem) scale(1.1); 
    transition: all .5s ease;
}
.feature-box__text{
    padding: .3em;
    text-transform: uppercase;
}

.feature-box-text-div{
    background: linear-gradient(to bottom left, rgba(255, 173, 0, 1), rgba(255, 173, 0, 1));
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-bottom: 2px solid rgb(5, 31, 114);
}

.feature-box-1{
    background: url(./images/assets/features/newyork.jpg);
    background-position: center;
    background-size: cover;
    
}

.feature-box-2{
    background: url(./images/assets/features/london.jpg);
    background-position: center;
    background-size: cover;
}

.feature-box-3{
    background: url(./images/assets/features/los-angeles.jpg);
    background-position: center;
    background-size: cover;
}

.feature-box-4{
    background: url(./images/assets/features/dubai.jpg);
    background-position: center;
    background-size: cover;
}





/*===================Footer section================*/

.footer{
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgba(5, 22, 77,1);
    max-height: 35vh;
    overflow-y: hidden;
    border-top:  2px solid rgba(255, 173, 0, 1);
    width: 100vw;
}

.footer__text__box{
    margin: 3rem auto;
    /* background: linear-gradient(to bottom left, rgba(255, 173, 0, 1), rgba(255, 173, 0, 1)); */
    width: 35vw;
}

.footer__text{
    padding: .5rem;
    text-transform: uppercase;
    color: rgba(255, 173, 0, 1);
    font-size: 2.4rem;
    border-bottom:  2px solid rgba(255, 173, 0, 1);
}

.footer__icon__div{
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    margin: 1rem auto;
    width: 500px;
}
.footer__icon__box{
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgba(5, 22, 77,1);;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    padding: .5rem;
   
}
.footer__icon{
    justify-content: center;
}
.footer__icon img{
    width: 40px;
}
.footer__icon__box:hover{
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translateY(-1.2rem) translateY(1.2rem) scale(1.3);
    transition: all .5s ease;
    border-bottom: solid 2px whitesmoke;
}

/*===================MODAL operations================*/

/* .modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out;
    border: 5px solid white;
    border-radius: 10px;
    z-index: 2;
    background-color: white;
    width: 800px;
    max-width: 80%;

}

.modal.active{
    transform:  translate(-50%, -50%) scale(1);

}
.modal-header{
    padding: 10px 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-transform:capitalize;

}

.modal-header .title{
    font-size: 2rem;
    font-weight: bold;
}

.modal-body{
    padding: 10px 15px;
}

.modal-body .close-button{
    width: 100px;
    height: 50px;
    font-weight: bold;
    border: 2px red solid;
    background-color: white;
    color: red;

}

.close-button-div{
    padding: 20px;
    justify-content: center;
}

.close-button-div button:hover{
    background-color: red ;
    color: white;
}

#overlay{
    position: fixed;
    /* opacity: 0; */
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 500ms ease-in-out;
    pointer-events: none;
}

#overlay.active{
    opacity: 1;
    pointer-events: all;
    background-color: rgba(0,0,0, .4); 
} */